.main-second-data{
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    margin: 20px;
}

.main-second-first{
    /* border: 1px solid black; */
    text-align: center;
}

.time-style{
    /* border: 1px solid red; */
    font-size: 30px;
}

.time-head{
    font-size: 50px;
    /* color: #56b4e7; */
    color: #12403C;
    font-weight: 600;
}

.time-para{
    color: gray;
    /* font-weight: bold; */
    /* font-family: Georgia, 'Times New Roman', Times, serif; */
}

.icon-time{
    color: #00A76F;
}

.main-second-second{
    /* border: 1px solid black; */
    text-align: center;
}

.main-second-third{
    /* border: 1px solid black; */
    text-align: center;
}

.main-third-data{
    /* border: 1px solid red; */
}

.delivery-loc{
    border: 1px solid rgb(210, 210, 210);
    padding: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
}

.delivery-loc h1{
    border-bottom: 1px solid rgb(218, 216, 216);
    border-bottom-style: dashed;
    font-weight: 600;
    /* color: gray; */
    color: #5DC4D1;
}

.delivery-loc p{
    color: gray;
}

.venue-loc{
    border: 1px solid rgb(210, 210, 210);
    padding: 20px;
    /* margin-top: 20px; */
    margin-bottom: 20px;
    margin-left: 20px;
}

.venue-loc h1{
    border-bottom: 1px solid rgb(218, 216, 216);
    border-bottom-style: dashed;    font-weight: 600;
    /* color: gray; */
    color: #5DC4D1;
}

.venue-loc p{
    color: gray;
}

.fancyHeading {
    display: inline-block;
    padding-left: 20px;
    color: black;
    font-size: 1.5em;
    font-weight: bold;
    position: relative;
    text-align: center;
    width: fit-content;
    /* margin-left: 60px; */
    /* border-radius: 5px; */
    background: rgb(255,255,255);
/* background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(227,252,231,1) 0%, rgba(50,233,188,1) 100%, rgba(251,249,218,1) 100%, rgba(251,249,218,1) 100%); */
  }
  
  /* .fancyHeading::before,
  .fancyHeading::after {
    content: "";
    position: absolute;
    top: 50%;
    width: 40px; 
    height: 52px; 
    background-color: #64C8D4;
  }
  
  .fancyHeading::before {
    left: -20px; 
    transform: translateY(-50%) translateX(-50%);
  }
  
  .fancyHeading::after {
    right: -19.5px; 
    transform: translateY(-50%) translateX(50%);
  }
  
  .fancyHeading::before {
    clip-path: polygon(100% 0, 50 50%, 100% 100%);
  }
  
  .fancyHeading::after {
    clip-path: polygon(0 0, 100% 50%, 0 100%);
  } */
  
  .main-fourth-data{
    /* border: 1px solid red; */
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  @import url('https://fonts.googleapis.com/css?family=Anton');

#root{
  position: relative;
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card{
  position: relative;
  width: 45%;
  height: 300px;
  border: 1px solid;
  overflow: hidden;
  /* box-shadow: 0 0 10px 2px; */
  transition:.6s;
  /* border: 1px solid red; */
  margin: 20px;
}
.filter{
  position: absolute;
  top: 0;left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(20,20,220,.8), rgba(220,220,20,.8), rgba(20,220,20,.8));
  z-index: 10;
  transition:.6s;
  opacity: 0;
}
.card img{
  position: absolute;
  top: 0;left: 0;
  max-width: 100%;
  transition:.6s;
}

.top,.bottom{
  position: absolute;
  left: 50%;
  background: #fff;
  height: 1px;
  width: 0;
  z-index: 10;
  transition: .6s;
}
.top{
  top:10%;
}

.bottom{
  bottom: 10%;
}

.left,.right{
  position: absolute;
  top: 50%;
  left: 10%;
  z-index: 10;
  width: 1px;
  height: 0;
  background: #fff;
  transition: .6s;
}
.right{
  left: 90%;
}
.desc{
  position: absolute;
  top: 10%;left: 10%;
  background: rgba(0,0,0,.3);
  width: 80%;
  height: 80%;
  z-index: 10;
  transition: .6s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
}
.desc p{
  font-size: 1.75em;
  color: white;
  font-family: 'Anton', bold;
}

.card:hover img{
  transform: scale(1.2);
}
.card:hover .filter{
  opacity: .15;
}

.card:hover .top{
  left: 10%;
  width: 80%;
}
.card:hover .bottom{
  left: 10%;
  width: 80%;
}

.card:hover .left{
  top: 10%;
  height: 80%;
}

.card:hover .right{
  top: 10%;
  height: 80%;
}

.card:hover .desc{
  opacity: 1;
}
