.card-dash-main{
    border: 1px solid rgb(207, 204, 204);
    height: 10rem;
}

.grid-card{
    /* border: 1px solid red; */
}

.grid-card-one{
    /* border: 1px solid green; */
    font-size: 14px !important;
}

.grid-card-two{
    /* border: 1px solid green; */
    font-size: 20px !important;
    font-weight: 600 !important;
    margin-top: 5px !important;
}

.select-dash-cont{
    /* border: 1px solid red; */
    width: 100px;
    height: 30px;
}


.select-dash-main-cont{
    /* border: 1px solid green; */
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.users-card-dash{
    /* border: 1px solid red; */
    display: flex;
    justify-content: flex-end;
    font-size: 13px !important;
    padding: 5px;
    margin-right: 10px;
    color: #FB3E7A !important;
}