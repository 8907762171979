.search-btn{
    border: 1px solid rgb(190, 190, 190);
    padding: 6px;
}


.wrapper{
/* position: absolute; */

/* transform: translate(-50%,-50%); */
/* width: 90%; */
display: flex;
/* box-shadow: 0 1px 20px 0 rgba(69,90,100,.08); */
box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
/* border: 1px solid red; */
/* margin-left: 450px;
margin-top: 250px; */
}

.wrapper .left{
width: 35%;
background: linear-gradient(to right,#01a9ac,#01dbdf);
padding: 30px 25px;
border-top-left-radius: 5px;
border-bottom-left-radius: 5px;
text-align: center;
color: #fff;
}

.wrapper .left img{
border-radius: 5px;
margin-bottom: 10px;
}

.wrapper .left h4{
margin-bottom: 10px;
}

.wrapper .left p{
font-size: 12px;
}

.head-img-user{
    /* border: 1px solid red; */
    display: flex;
    justify-content: end;
}

.head-img-user img{
    /* border: 1px solid red; */
    height: 50px;
    width: 50px;
    border-radius: 50px;
}

.wrapper .right{
width: 100%;
background: #fff;
padding: 30px 25px;
border-top-right-radius: 5px;
border-bottom-right-radius: 5px;
}

.wrapper .right .info,
.wrapper .right .projects{
margin-bottom: 25px;
/* border: 1px solid red; */
}

.wrapper .right .info h3,
.wrapper .right .projects h3{
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e0e0e0;
  /* color: #353c4e; */
  color: #01BABE;
text-transform: uppercase;
letter-spacing: 5px;
}

.wrapper .right .info_data,
.wrapper .right .projects_data{
display: flex;
flex-wrap: wrap;
justify-content: space-between;
/* border: 1px solid red; */
}

.inside-one-data{
    /* border: 1px solid red; */
    width: 60%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.inside-two-data{
    /* border: 1px solid green; */
    width: 30%;
    display: flex;
    justify-content: end;
}

.inside-two-data img{
    width: 150px;
    height: 150px;
    border: 4px solid #9bc6df;
}

.wrapper .right .info_data .data,
.wrapper .right .projects_data .data{
width: 45%;
}

.wrapper .right .info_data .data h4,
.wrapper .right .projects_data .data h4{
  color: #353c4e;
  margin-bottom: 5px;
}

.wrapper .right .info_data .data p,
.wrapper .right .projects_data .data p{
font-size: 13px;
margin-bottom: 10px;
color: #919aa3;
}

.wrapper .social_media ul{
display: flex;
}

.wrapper .social_media ul li{
width: 45px;
height: 45px;
background: linear-gradient(to right,#01a9ac,#01dbdf);
margin-right: 10px;
border-radius: 5px;
text-align: center;
line-height: 45px;
}

.wrapper .social_media ul li a{
color :#fff;
display: block;
font-size: 18px;
}

/* Add this in your CSS file, e.g., Users.module.css */
.tableContainer {
    width: 100% !important;
}

.search-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    width: 25%;
}

.search-icon {
    margin-right: 5px;
    color: #888;
}

.search-btn {
    border: none;
    outline: none;
    width: 100%;
}

.search-btn::placeholder {
    color: #aaa;
}
