.main-first-card{
    /* border: 1px solid red; */
    display: flex;
    margin: 20px;
    width: 97%;
    gap: 10px;
}

.main-first-left{
    /* border: 1px solid rgb(80, 0, 210); */
    width: 60%;
}

.main-first-left img{
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px; */
}

.main-first-right{
    border: 8px solid #FEC418;
    width: 40%;
    text-indent: 20px;
    border-radius: 20px;
    margin-left: 10px;
    /* box-shadow: 0 0 0 2px rgba(218,102,123,1), 8px 8px 0 0 rgba(218,102,123,1); */
    background-color: #FFFFFF;
    /* margin-bottom: 7px; */
    /* margin-top: 8px; */
}

.main-first-right p {
    padding: 10px;
}

.bannerid-text{
    /* border: 1px solid red; */
    color: cadetblue;
    font-size: 20px;
}

.style-bannername{
    /* border: 1px solid red; */
    font-size: 25px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

.style-bannertype{
    background: linear-gradient(106.8deg, rgb(117, 255, 220) 6%, rgb(163, 216, 255) 47.6%, rgb(248, 215, 251) 87.8%);
    color: white;
    /* padding: 0.5rem; */
    border-radius: 10px;
    width: fit-content;
    margin-left: 30px;
    font-size: 20px;
    font-weight: bold;
}

.style-banner-created{
    /* border: 1px solid red; */
    color: gray;
    font-weight: 600;
}

.style-bannerdisc-main {
    margin-top: 10px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 10px;
}

.style-bannerdisc-main h3 {
    margin-bottom: 10px;
    font-size: 1.5em;
    color: #333;
}

.style-bannerdisc-main p {
    font-size: 1em;
    color: #666;
}

/* React Slick Slider Styles */
.main-first-left{
    margin: 0 auto;
    width: 40vw;
    min-height: 400px;
    box-shadow:
    inset 0 0 50px #fff,      /* inner white */
    inset 20px 0 80px #7FD8E0,   /* inner left magenta short */
    inset -20px 0 80px rgb(254, 255, 255),  /* inner right cyan short */
    inset 20px 0 300px rgb(255, 235, 255),  /* inner left magenta broad */
    inset -20px 0 300px rgb(247, 255, 255), /* inner right cyan broad */
    0 0 30px #fff,            /* outer white */
    -5px 0 40px rgb(255, 239, 255),        /* outer left magenta */
    10px 0 40px rgb(255, 255, 255);         /* outer right cyan */
    padding: 10px;
    /* border-top: 20px solid #25B09B;     
    border-right: 20px solid #F03355;  
    border-bottom: 20px solid #514B82; 
    border-left: 20px solid #FFA516;  */
    /* border-radius: 20px; */
    border-image: fill 0 linear-gradient(#0003,#000); /* your gradient here */
}
.sliderbox{
    position: relative;
    width: 300px;
    min-height: 300px;
    overflow: hidden;
    /* border: 1px solid red; */
}
.slidercontainer{
    display: flex;
    flex-direction: row;
    /* border: 1px solid red; */
}
.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.slick-list:focus {
    outline: none;
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none;
}

.slick-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Slider Navigation Arrows */
.slick-prev,
.slick-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.slick-prev:hover,
.slick-next:hover {
    background-color: rgba(255, 255, 255, 0.8);
}

.slick-prev {
    left: 10px;
}

.slick-next {
    right: 10px;
}

/* Slider Dots */
.slick-dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.slick-dots li {
    margin: 0 5px;
}

.slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 10px;
    height: 10px;
    padding: 5px;
    border: none;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.slick-dots li button:hover {
    background-color: rgba(255, 255, 255, 0.8);
}

.slick-dots li.slick-active button {
    background-color: rgba(255, 255, 255, 0.8);
}
