.main-order-details{
    /* border: 1px solid red; */
    display: flex;
    height: fit-content;
}

.sub-details-one{
    border: 1px solid rgb(213, 213, 213);
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px; */
    width: 95%;
    margin: 20px;
    height: fit-content;
}

.sub-details-one-new{
    border: 1px solid rgb(235, 235, 235);
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    width: 95%;
    margin: 20px;
    height: fit-content;
    padding-bottom: 20px;
}

.sub-details-two{
    border: 1px solid rgb(235, 235, 235);
    width: 40%;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    margin: 5px;
    border-radius: 10px;
    height: fit-content;
    margin-top: 18px;
}

.customer-edit{
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: #B179D6; */
    background-color: #0077b6;
    color: white !important;
}

.customer-edit button{
    /* border: 1px solid red; */
    border: none;
    margin: 5px;
    background-color: transparent;
    font-size: 20px;
    /* color: #637381; */
    color: white;
    width: 40px;
}

.card-text-head{
    /* border: 1px solid red; */
    padding: 5px;
    color: #5AC3D0;
}

.inside-card-one{
    border-bottom: 1px solid rgb(208, 208, 208);
    display: flex;
    margin: 5px;
    border-bottom-style: dashed;
}

.status-one{
    /* border: 1px solid  blue; */
    display: flex;
    flex-direction: column;
    line-height: 50px;
    width: 50%;
    text-indent: 40px;
}

.status-text{
    color: gray;
}

.second-inside-card{
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: end;
    /* margin-right: 20px; */
}

.sub-inside-one{
    /* border: 1px solid red; */
    width: 40%;
}

.sub-inside-one p{
    /* border: 1px solid red; */
    padding: 5px;
    padding-right: 40px;
}

.sub-order-text{
    /* border: 1px solid red; */
    font-size: 15px;
    color: gray;
}

.sub-order-text-one{
    /* border: 1px solid red; */
    font-size: 15px;
    color: gray;
}

.inside-price-data{
    color: green;
    display: flex;
    justify-content: space-between;
}

.inside-price-data-one{
    color: red;
    display: flex;
    justify-content: space-between;
}

.inside-price-grand-data{
    color: rgb(0, 0, 0);
    display: flex;
    justify-content: space-between;
    font-size: 20px;
}

.orderdetails-heading{
    border-bottom: 2px solid rgb(208, 208, 208);
    border-bottom-style:dashed;
    margin-left: 20px;
    padding: 20px;
    color: #187DB8;
    font-family: 'Times New Roman', Times, serif;
    margin-bottom: 10px;
}

.control-btns{
    /* border: 1px solid red; */
    margin-top: 5px;
}

.Container{
    /* margin: 5px; */
    margin-top: 100px !important;
    /* box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em; */
    /* height: 30rem; */
    /* border: 1px solid red; */
}

.sub-inside-two{
    /* border: 1px solid red; */
    /* border-bottom: 1px solid rgb(208, 208, 208);
    border-bottom-style: dashed; */
}

.sub-inside-three{
    /* border: 1px solid red; */
    /* border-bottom: 1px solid rgb(208, 208, 208);
    border-bottom-style: dashed; */
}

.sub-inside-two p{
    /* border: 1px solid red; */
    padding: 5px;
}

.sub-inside-three p{
    /* border: 1px solid red; */
    padding: 5px;
}

.card-text-head-two{
 /* border: 1px solid red; */
 padding: 5px;
 /* color: #5AC3D0; */
 color: white;
 /* border-bottom: 1px solid rgb(208, 208, 208); */
 /* border-bottom-style: dashed; */
}

.card-text-head-four{
    /* border: 1px solid red; */
    padding: 5px;
    color: #5AC3D0;
    /* border-bottom: 1px solid rgb(208, 208, 208); */
    /* border-bottom-style: dashed; */
   }

.inside-address-details{
    /* border: 1px solid red; */
    font-size: 15px;
    font-weight: 500;
}

.order-info-edit{
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 30px;
    /* border-bottom: 1px solid rgb(208, 208, 208); */
    /* border-bottom-style: dashed; */
    /* background-color: #B179D6; */
    background-color: #0077b6;
    color: white !important;
}

.order-info-edit button{
    /* border: 1px solid red; */
    border: none;
    margin: 5px;
    background-color: transparent;
    font-size: 15px;
    /* color: #637381; */
    color: white;
    width: 40px;
}

.customer-personal-info{
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    margin: 5px;
    /* border-bottom: 1px solid rgb(208, 208, 208);
    border-bottom-style: dashed; */
}

.cust-id{
    font-size: 20px;
    color: #187DB8;
    line-height: 10px;
    margin-top: 20px;
}

.cust-mob{
    font-size: 15px;
    /* color: #187DB8; */
    line-height: 10px;
    /* margin-top: 20px; */
}

.cust-name{
    font-weight: bold;
}

.main-phone{
    border: 1px solid green;
    display: flex;
    align-items: center;
}

.cust-phone{
    /* border: 1px solid red; */
    color: #187DB8;
    margin-right: 5px;
    font-size: 10px;
}

.status-one-new{
    /* border: 1px solid red; */
    text-align: start;
    margin-bottom: 12px;
    color: gray;
}

.status-one-new p{
    padding: 10px;
    /* margin-bottom: 10px; */
    line-height: 5px;
    /* border: 1px solid red; */
}

.order-items-main-card{
    /* border: 1px solid red; */
    width: 100%;
}

.inside-order-items-card{
    /* border: 1px solid red; */
}

.inside-items-one{
    /* border: 1px solid red; */
    display: flex;
    justify-content: space-between;
}

.order-multi{
    /* border: 1px solid red; */
    display: flex;
    justify-content: start;
    align-items: center;
}

.order-multi button{
    /* border: 1px solid red; */
    margin-top: 10px;
}

.order-multi p{
    /* border: 1px solid green; */
    font-size: 20px;
    position: absolute;
    margin-left: 35px;
    margin-bottom: 5px;
    /* color: gray; */
    color: white;
}

.sub-order-inside-details{
    border: 1px solid rgb(232, 232, 232);
    margin-top: 20px;
    margin: 10px;
}

.sub-details-new-two{
    width: 40%;
}

.cards-container-new{
    border: 1px solid rgb(203, 203, 203);
    margin-top: 20px;
    margin-right: 10px;
}