body{
    /* background-color: whitesmoke; */
}

.Container {
    padding: 0.5rem;
    display: grid;
    gap: 2rem;
    align-content: baseline;
    /* min-height: 87.3vh; */
    /* height: 100%; */
    /* background-color: #a2cdff; */
    /* background-color: #0077b6; */

    /* background-color: #FBF9F6; */
    /* border: 6px solid ghostwhite; */
    /* margin-left: 5px; */
    margin-top: 100px;
    /* margin-right: 5px; */
    /* box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset; */
}
.control-btns {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    /* margin-right: 69px; */
    flex-wrap: wrap;
    /* border: 1px solid red; */
}
.button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    border: 1px solid #ccc;

    background-color: rgb(255, 255, 255);
    color: #0077b6;
    gap: 0.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 1rem;
}
.button:hover {
    background-color: #ececec;
}

.wrapper {
    /* padding: 15px 15px 40px; */
    background-color: #fff;
    border-radius: 4px;
    display: grid;
    gap: 0.5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
        Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
        sans-serif;
    /* width: 90%; */
    /* border: 1px solid rgb(230, 230, 230); */
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
    /* box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px; */
    /* box-shadow: 0px 9px 30px rgba(5, 122, 255, 0.2); */
    /* box-shadow: 0px 9px 30px rgba(255, 149, 5, 0.3); */
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, .13), 0 1px 2px 0 rgba(0, 0, 0, .11);
    padding: 10px;
}
.wrapper h3 {
    border-bottom: 1px solid #ddd;
    padding: 0 0 5px;
    margin: 0 0 15px;
    font-size: 1.3rem;
    display: block;
    font-weight: 500;
    line-height: 1.2;
    font-family: inherit;
    color: #212529;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-align: left;
}
.active {
    border-color: #dee2e6 #dee2e6 #f9f9f9 !important;
    background-color: #f9f9f9 !important;
}
.Table {
    display: grid;
    width: 100%;
    overflow: auto;
}
.Table table {
    border-collapse: collapse;
    table-layout: auto;
    /* width: 100%; */
    min-width: 700px;
    text-align: left;
    border: 1px solid #ccc;
}
.Table table thead {
    color: white;
}
.Table table thead th {
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #fff;
    /* background-color: #1e87db; */
    /* background-color: #4FBDCC; */
    /* background-color: #B179D6; */
    /* background-color: #0077B6; */
    background-color: #FB3E7A;
    padding: 1rem 0rem;
    /* letter-spacing: 0.05rem; */
    text-transform: uppercase;
    vertical-align: middle;
    text-align: center;
}
.Table table tbody tr {
    text-align: center;
    cursor: pointer;
    color: #777;
}
.Table table tbody tr td {
    border-bottom: 1px solid #ccc;
    font-family: "Montserrat", sans-serif;
    font-size: 15px;
    padding: 0.5rem 0rem;
    color: inherit;
    cursor: pointer;
    text-transform: capitalize;
}
.Action-btns {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
}
.Action-btns button {
    border: none;
    background-color: inherit;
    font-size: 1.4em;
    color: #0077b6;
    cursor: pointer;
}
.Status-active span {
    background-color: #def7ec;
    color: #0e9f6e;
    padding: 0.5rem;
    border-radius: 10px;
}
.Status-inactive span {
    padding: 0.5rem;
    border-radius: 10px;
    background-color: #fde8e8;
    color: #f05252;
}

/* Add this to Common.module.css or an appropriate CSS file */
.table-control-btns {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 1rem;
    /* border: 1px solid red; */
}

.table-control-btns .button {
    /* margin-left: 1rem; */
}

.table-control-btns .button.active {
    /* background-color: #007bff !important; */
    background-color: #0077B6 !important;
    color: white;
    border: none;
}

/* Add this to your Common.module.css or Users.module.css */

/* Smooth fade-in effect for table content */
.fade-in {
    animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}


@media screen and (max-width: 600px) {
    .Container {
        padding: 1rem;
    }
}

/* styles for delete modal */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensures modal is above other elements */
}

.modal-content {
    background-color: #fff; /* White background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Soft shadow */
    width: 400px;
    max-width: 80%; /* Responsive design */
    text-align: center;
}

.modal-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
}

.del-warning{
    /* border: 1px solid red; */
    color: rgb(166, 161, 85);
    font-size: 50px;
}

.del-main-btn{
    color: red;
    font-size: 23px;
}